:root {
  --access-denied-background-color-primary: transparent;
  --access-denied-text-color-primary: var(--texas-gray);
  --access-denied-background-color-secondary: var(--robin-s-egg-blue);
  --access-denied-text-color-secondary: var(--texas-gray);
  --access-denied-button-join-bg: rgba(255, 255, 255, 1);
  --access-denied-button-join-border: var(--robin-s-egg-blue);
  --access-denied-button-login-border: var(--access-denied-text-color-primary);
}

.access-denied-container {
  width: 100%;
  padding: 2rem;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}

.access-denied-access-denied {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--access-denied-background-color-primary);
}

.access-denied-accessdeniedheader {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}

.access-denied-stophand {
  width: 70px;
  height: 70px;
}

.access-denied-frame-wrap {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.access-denied-message-heading {
  color: var(--access-denied-text-color-primary);
  height: auto;
  font-size: 40px;
  font-style: Bold;
  text-align: left;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .access-denied-message-heading {
    font-size: 24px;
  }

  .access-denied-stophand {
    color: var(--access-denied-text-color-primary);
    width: 72px;
    height: auto;
  }
}

.access-denied-message-text {
  color: var(--access-denied-text-color-primary);
}

.access-denied-subheading {
  color: var(--access-denied-text-color-primary);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  font-weight: bold;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}

.access-denied-accesdeniedcontent {
  gap: 7px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.access-denied-subheading {
  color: var(--access-denied-text-color-primary);
  width: 318px;
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 36px;
  font-stretch: normal;
  text-decoration: none;
}

.access-denied-text {
  color: var(--access-denied-text-color-primary);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}

.access-denied-groupjoinandlogin {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}

.access-denied-button-login {
  gap: 10px;
  display: flex;
  padding: 9px 15px;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 4px;
  background-color: var(--access-denied-button-join-bg);
  border: 3px solid var(--access-denied-button-login-border);
}

.access-denied-login-text {
  color: var(--access-denied-text-color-primary);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 26px;
  font-stretch: normal;
  text-decoration: none;
}

.access-denied-button-join {
  gap: 10px;
  display: flex;
  padding: 9px 15px;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--access-denied-button-join-border);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--access-denied-background-color-secondary);
  border: 3px solid var(--access-denied-background-color-secondary);
}

.access-denied-join-text {
  color: var(--access-denied-text-color-secondary);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 26px;
  font-stretch: normal;
  text-decoration: none;
}

.access-denied-frame245 {
  gap: 10px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}

.access-denied-text-cost {
  color: var(--access-denied-text-color-primary);
  width: 359px;
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
