.committee-chair-info {
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;
  font-family: Montserrat, sans-serif;
}

.committee-chair-image-container {
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin-right: 1rem;
  border: 1px solid #e0e0e0;
  position: relative;
  flex-shrink: 0;
}

.committee-chair-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.committee-chair-position {
  color: #CE1F22;
  font-weight: 500;
  font-size: 0.7rem;
  text-transform: uppercase;
  margin-bottom: 0.1rem;
  font-family: Montserrat, sans-serif;
  font-variant: small-caps;
  letter-spacing: 0.08em;
  line-height: 1;
}

.committee-chair-name {
  color: #CE1F22;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 0.2rem;
  line-height: 1.2;
  font-variant: small-caps;
  letter-spacing: 0.02em;
}

.committee-chair-title,
.committee-chair-firm {
  color: #666;
  font-size: 1rem;
  margin-bottom: 0.2rem;
  line-height: 1.3;
}

.committee-chair-location {
  color: #666;
  font-size: 1rem;
  margin-bottom: 0.2rem;
  line-height: 1.3;
  font-style: italic;
}

.committee-chair-contact {
  margin-top: 0.5rem;
}

.committee-chair-contact button {
  font-size: 0.75rem;
  font-family: Montserrat, sans-serif;
} 