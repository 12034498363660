.navigation ul.desktop-menu > li > button,
.navigation ul.desktop-menu > li > a {
  padding-bottom: 0.25rem;
  font-weight: 400;
}
.navigation {
  padding-left: 2rem;
}

#navigation {
  .txnavbar.icon {
    padding: 1px;
    position: absolute;
    right: 0px;
    bottom: 4px;
  }
  ul.desktop-menu > li > a.item,
  ul.desktop-menu > li > button.item {
    font-size: 1rem;
    line-height: 1;
    font-family: Montserrat;
    margin-left: 1rem;
  }
  ul.desktop-menu > li > button.item {
    padding-right: 20px;
    position: relative;
  }
}