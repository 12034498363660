.ui.committee-list.grid > [class*='two column'].row > .column {
  width: 100% !important;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .ui.committee-list.grid > [class*='two column'].row > .column {
    width: 45% !important;
  }
}

.committee-item {
  border: 1px solid var(--texas-light-gray);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.committee-image {
  width: 36px;
}
.committee-list-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: flex-start;
}

.committee-column {
  flex: 1 0 300px;
  max-width: 100%;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .committee-column {
    max-width: calc(50% - 1rem);
  }
}

@media screen and (min-width: 1200px) {
  .committee-column {
    max-width: calc(33.333% - 1.334rem);
  }
}

.committee-overview-wrap {
  height: 100%;
}

.committee-overview-wrap .description,
.committee-overview-wrap .item .description,
.ui.items .item .description,
.ui.items .item > .content > .description {
  color: #4B5563;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-height: 6em !important; /* 4 lines * 1.5 line-height */
  min-height: unset !important;
  position: relative;
}

/* Remove any fixed heights that might interfere */
.committee-overview-wrap .content {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: visible;
}

/* Remove the gradient fade since it can interfere with ellipsis */
.committee-overview-wrap .description::after {
  display: none;
}

/* Ensure proper spacing for the leadership section */
.committee-chairs {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--texas-light-gray);
}

.committee-chairs-heading {
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 1;
}
.committee-readmore-button {
  appearance: button;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  fill: var(--dl-color-primary-500);
  color: var(--texas-red);
  background-color: transparent;
  width: 100%;
  font-variant: small-caps;
  height: 32px;
  border: none;
  border-bottom: 1px solid var(--texas-light-gray);
}

/* Avatar 7 */
.avatar {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 36px;
    height: 36px;
    background: #d9cbf6ff;
    opacity: 1;
    overflow: hidden;
    border-radius: 4px;
}
.avatar img {
    width: 36px;
    height: 36px;
}
.avatar .badge {
    width: 9px;
    height: 9px;
    border-radius: 4.5px;
}
.avatar.active .badge {
    background: #000000ff;
    opacity: 0;
    border-width: 1.5px;
    border-color: #ffffffff;
}
.avatar.inactive .badge {
    background: #000000ff;
    opacity: 0;
    border-width: 1.5px;
    border-color: #ffffffff;
}
.avatar.idle .badge {
    background: #000000ff;
    opacity: 0;
    border-width: 1.5px;
    border-color: #ffffffff;
}
.avatar.do_not_disturb .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    line-height: 9px;
    color: #ffffffff;
    background: #000000ff;
    opacity: 0;
    border-width: 1.5px;
    border-color: #ffffffff;
}
