.header-container {
  /*align-items: flex-start;*/
  display: flex;
}

.latest {
  margin-left: 1rem;
}

.latest h4 {
  color: #5e5e5e;
  margin: 0;
}

.pph-heading {
  color: #666;
  font-size: 1.5rem;
  margin-bottom: 4px;
}

.element-card {
  border: solid 1px crimson;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  width: 100px;
}

.pt-element {
  color: crimson;
}
.pt-element-text {
  font-size: 2.5rem;
}

.pt-element-subtext {
  font-size: 1.25rem;
}

.latest {
  display: flex;
  flex: 1;
  flex-direction: column;
}
