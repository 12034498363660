.edit-mode .txbizLawCTA_wrap{
  position:static;
  max-width:100%;
}
.txbizLawCTA.wrapper{  
max-width: none !important;
margin-right:-3rem;
padding:2rem;
display:flex;
justify-content:center;
align-items:stretch;
height:200px;
flex-wrap:wrap;
flex-direction:column;
align-content:center;
    background-size: cover !important;
    background-attachment: fixed;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0)), url(./img/capital-building-cropped.jpeg);
    background-position: 0 0, 0 0;
    background-repeat: repeat, no-repeat;
    background-size: auto, auto;
}


.txbizLawCTA_wrap {
  border-radius:1rem;
  background:#CE1F22;
  flex: 0 0 auto;
  max-width: var(--layout-container-width);
  width:100%;
  display: flex;
  position: relative;
  align-items: stretch;
  justify-content: space-between;
  padding-left:3rem;
  .button { 
  padding: 1rem 2rem; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  font-size: 18px; 
  font-weight: 400; 
  line-height: 1.2; 
  color: #FFFFFFFF; /* white */
  background: #3C3C3CFF; 
  border: none; 
  border-radius: .5rem; /* border-xl */
}
}
.maintext-container {
  flex: 0 0 auto;
  width: 50%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color:white;
}
.subtext-container {
  flex: 0 0 auto;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color:white;
}
.button-container {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
/* Desktop styles (default) */
@media (min-width: 768px) {
  .txbizLawCTA_wrap {
    max-width:  var(--layout-container-width);
    flex: 0 0 auto;
  }

  .maintext-container {
    flex: 0 0 auto;
    width: 50%;
    height: 100px;
  }

  .subtext-container {
    flex: 0 0 auto;
    width: 20%;
    height: 100px;
  }

  .button-container {
    flex: 0 0 auto;
    width: 200px;
  }
}

/* Tablet styles */
@media (max-width: 767px) and (min-width: 480px) {
  .txbizLawCTA_wrap {
    max-width: 100%;
    flex-direction: column;
  }

  .maintext-container,
  .subtext-container,
  .button-container {
    flex: 1 0 auto;
    width: 100%;
  }
}

/* Mobile styles */
@media (max-width: 479px) {
  .txbizLawCTA_wrap {
    max-width: 100%;
    flex-direction: column;
  }

  .maintext-container,
  .subtext-container,
  .button-container {
    flex: 1 0 auto;
    width: 100%;
  }
  
}