.login-view-wrapper {
  display: flex;
  width: 90%;
}
.ui.primary.segment.tx-login-form-header {
  border-bottom: 0 solid transparent;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
}
.ui.segments.tx-login-form {
  border: 1px solid var(--texas-red);
  padding: 2rem;
}
#challenge-text {
  margin-right: 2rem;
}
@media (max-width: 768px) {
  .login-view-wrapper {
    flex-direction: column-reverse;
  }
  .login-view-instructions {
    margin-right: 0rem;
    margin-top: 2rem;
  }
}
