.gumlet-video-container {
  margin-bottom: 2rem;
  /* Ensure containers don't collapse into each other */
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

/* Ensure each video player has space between them */
.gumlet-video-container + .gumlet-video-container {
  margin-top: 2rem;
}

.video-stats {
  margin-top: 1rem;
}

.video-progress {
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 8px;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #56ccf2;
  transition: width 0.3s ease;
}

.progress-stats {
  margin-top: 0.5rem;
  color: #666;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.completion-badge {
  color: #28a745;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

/* Ensure each video player is responsive */
.video-js {
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow: hidden;
}

/* Override default video.js styles to ensure unique instances don't conflict */
.video-js .vjs-control-bar {
  z-index: 2;
}

.video-js .vjs-big-play-button {
  z-index: 1;
}

/* CLE Code Styling */
.cle-code-section {
  margin-top: 2rem;
}

.cle-code-display {
  margin-top: 1rem;
}

.cle-code {
  background: #f8f9fa;
  border: 2px solid #28a745;
  border-radius: 4px;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  color: #28a745;
  margin-top: 0.5rem;
  letter-spacing: 1px;
}

.show-code-button {
  margin-top: 1rem;
}

.error {
  color: #dc3545;
  padding: 1rem;
  margin: 1rem 0;
  background: #f8d7da;
  border-radius: 4px;
}

/* Editor Sidebar */
.video-sidebar-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ui.segment .ui.list .item {
  margin-bottom: 0.5rem;
}

.ui.segment .ui.list .header {
  margin-bottom: 0.25rem;
  color: #666;
  font-size: 0.9rem;
}

/* Loading state */
.video-loading {
  background: #f8f9fa;
  border-radius: 8px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .video-js {
    aspect-ratio: 16/9;
    height: auto !important;
  }

  .gumlet-video-container {
    padding: 0.5rem;
  }
}

.share-link-button {
  margin-top: 1rem;
}
