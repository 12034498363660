.block.committeeListTemplate h2.headline{
    height: 1rem;
    margin-bottom: 0rem !important;
}
.committee-item {
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem !important;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

/* More specific rule for committee items inside the wrapper */
.committee-list-wrapper .committee-item {
  padding: 0.75rem;
}

.committee-item:after {
  content: '';
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 20px;
  height: 20px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23666" d="M9.29 6.71a.996.996 0 0 0 0 1.41L13.17 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.5;
  transition: all 0.2s ease;
}

/* Adjust arrow positioning for compact committee items */
.committee-list-wrapper .committee-item:after {
  top: 0.75rem;
  right: 0.75rem;
}

.committee-item:hover {
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transform: translateY(-1px);
}

.committee-item:hover:after {
  opacity: 1;
  transform: translateX(4px);
}

.committee-overview-wrap {
  margin-bottom: 1.25rem !important;
  border: none !important;
}

.committee-overview-wrap .header {
  font-size: 1.5rem !important;
  color: #000;
  margin-bottom: 1rem !important;
  text-decoration: none;
  font-weight: 600 !important;
  display: flex;
  align-items: flex-start;
  line-height: 1.2;
}

.committee-overview-wrap .header:before {
  display: none !important;
}

.committee-overview-wrap .description {
  color: #4B5563;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-height: none;
  position: relative;
}

.committee-overview-wrap .content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ui.items .item .description,
.ui.items .item > .content > .description {
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-top: 0 !important;
  max-width: 100% !important;
}

.committee-wrap {
  display: none;
}

.committee-chairs {
  background: #f8f9fa;
  border-radius: 6px;
  padding: 1.5rem;
  margin-top: 0.5rem;
}

/* More compact committee chairs section within the committee list */
.committee-list-wrapper .committee-chairs {
  padding: 0.75rem;
}

.committee-chairs-heading {
  font-size: 1.15rem;
  color: #111827;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

/* Committee Chair Info Styling */
.committee-chair-info {
  margin-bottom: 1.75rem;
  display: flex;
  align-items: flex-start;
}

.committee-chair-info:last-child {
  margin-bottom: 0;
}

.committee-chair-info .person-title {
  color: #CE1F22;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.85rem;
  margin-bottom: 0.1rem;
}

.committee-chair-info .person-name {
  color: #CE1F22;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 0.2rem;
}

.committee-chair-info .person-role,
.committee-chair-info .person-firm,
.committee-chair-info .person-location {
  color: #666;
  font-size: 1rem;
  line-height: 1.3;
  margin-bottom: 0.2rem;
}

.ui.grid {
  margin-top: 0.1rem !important;
  margin-bottom: 0.5rem !important;
}

.ui.grid > .column {
  padding: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

/* More specific rule for committee grid columns */
.committee-list-wrapper .ui.grid > .column {
  padding: 0.25rem !important;
}

/* Add top margin to the grid containing committees */
.committee-list-wrapper .ui.grid {
  margin-top: 0.75rem !important;
}

.ui.label {
  background-color: #F3F4F6;
  color: #4B5563;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: normal;
  border-radius: 4px;
}

.ui.segment {
  box-shadow: none;
  border: none;
  padding: 1.5rem !important;
  margin: 0 0 1rem 0 !important;
  background: transparent;
}

/* Adding more specific rule for committee segments */
.committee-list-wrapper .ui.segment {
  padding: 0.5rem !important;
}

/* Add more specific selectors for the committee section title */
.committee-list-wrapper .ui.segment > h2.ui.header {
  margin-bottom: 2rem !important;
  padding-bottom: 0.5rem !important;
}

.ui.segment > h2.ui.header {
  font-size: 1.75rem;
  color: #111827;
  margin-bottom: 1rem !important;
  margin-top: 0 !important;
  font-weight: 600;
}

.committee-list-wrapper {
  padding: 0.5rem !important;
  margin: 0 !important;
  margin-top: 1rem !important;
}

.committee-list-wrapper .ui.items .item, 
.committee-list-wrapper .ui.items .item .content,
.committee-list-wrapper .committee-item *,
.committee-list-wrapper [class*="committee"] {
  border-left: none !important;
}

.committee-list-wrapper *[style*="border-left"] {
  border-left: none !important;
}

.committee-list-wrapper *[style*="border-color: #CE1F22"], 
.committee-list-wrapper *[style*="border-color:#CE1F22"],
.committee-list-wrapper *[style*="border-left-color: #CE1F22"],
.committee-list-wrapper *[style*="border-left-color:#CE1F22"] {
  border-color: transparent !important;
  border-left-color: transparent !important;
}

/* Add a special class for sections with titles (outside the component) */
.committee-list-wrapper h1 + .ui.segment,
.committee-list-wrapper h2 + .ui.segment,
.committee-list-wrapper h3 + .ui.segment,
h1 + .committee-list-wrapper,
h2 + .committee-list-wrapper,
h3 + .committee-list-wrapper {
  margin-top: 1rem !important;
}

/* Extra selector to target the Substantive Committees section specifically */
h2:contains("Substantive Committees") + .committee-list-wrapper,
h2:contains("Substantive Committees") ~ .committee-list-wrapper {
  margin-top: 1.25rem !important;
}

/* Space out segments with no explicit headers */
.committee-list-wrapper .ui.segment:not(:has(h2.ui.header)) {
  padding-top: 1rem !important;
} 