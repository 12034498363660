.askanythingwrap-askanythingwrap {
  width: 100%;
  height: auto;
  display: flex;
  padding: 16px;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-radius: 8px;
  background-color: var(--texas-robin-egg-blue);
}
.askanythingwrap-askanythingcontent {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.askanythingwrap-frame56 {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.askanythingwrap-text {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 40px;
  font-style: Bold;
  text-align: left;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
.askanythingwrap-text02 {
  color: rgba(255, 255, 255, 1);
  width: 359px;
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.askanythingwrap-group {
  gap: 22px;
  display: flex;
  align-items: flex-start;
}
.ui.button.askanythingwrap-askbutton {
  gap: 10px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  color: var(--texas-robin-egg-blue);
  border-radius: 4px;
  background-color: rgba(235, 253, 255, 1);
}
.askanythingwrap-text04 {
  color: rgba(18, 183, 236, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 26px;
  font-stretch: normal;
  text-decoration: none;
}
.ui.button.askanythingwrap-joinbutton {
  gap: 10px;
  color: white;
  display: flex;
  padding: 9px 11px;
  overflow: hidden;
  align-items: flex-start;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  background-color: transparent;
  height: auto;
  font-stretch: normal;
  text-decoration: none;
}
.askanythingwrap-text06 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 26px;
  font-stretch: normal;
  text-decoration: none;
}
.askanythingwrap-image {
  width: 177px;
  height: 266px;
}
