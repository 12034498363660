.grid-label {
  color: var(--texas-red);
  margin-bottom: 0px;
  font-size: 0.625rem;
}

/* Add red line placeholder when no document type */
.grid-label:empty {
  height: 2px;
  background-color: var(--texas-red);
  margin: 8px 0;
  width: 60px;
  display: block;
}

.searchBlock-container {
  .ui.card .meta {
    font-size: 13px;
  }
}
.list-creators {
  margin-right: 0.5rem !important;
  margin-left: 0.2rem;
  display: inline-block;
}
.grid-list-footer {
  margin-top: 1rem;
}
.grid-list-location {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-size: 0.625rem;
}
.grid-list-icon {
  color: var(--texas-red);
  font-size: 2rem;
  position: relative;
  top: 8px;
  transition: transform 0.2s ease, color 0.2s ease;
}

.grid-list-footer a:hover .grid-list-icon {
  transform: translateY(-2px);
  color: #a01e21; /* Slightly darker shade of texas-red for hover */
}

.grid-list-button {
  background-color: var(--texas-charcoal);
  color: white;
}
.grid-list-description {
  display: -webkit-box; /* For Safari */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7; /* Number of lines to show */
  overflow: hidden; /* Hide overflow */
  max-height: calc(1.5em * 3); /* Adjust based on line height */
}
.grid-list-heading {
  margin-bottom: 0px;
  font-size: 1.1875rem;
  font-weight: 700;
  color: var(--texas-gray);
  display: block;
}
.grid-list-content {
  position: relative;
  overflow: hidden;
}
.grid-list-sash {
  background: #fcf4f4;
  border: 1px solid var(--texas-red);
  position: absolute;
  transform: rotate(25deg);
  right: -3rem;
  font-size: 0.8rem;
  padding: 0.1rem 4rem;
}

.clickable-card {
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.clickable-card:hover {
  transform: translateY(-2px);
}

.clickable-card:focus {
  outline: none;
}

.clickable-card:focus .ui.card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.clickable-card .ui.card {
  transition: box-shadow 0.2s ease;
}

.clickable-card:hover .ui.card {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.grid-list-footer a,
.grid-list-footer button {
  position: relative;
  z-index: 1;
}
