.preview-image{width:150px;
               height:auto;
               aspect-ratio: 0.8235294118;
              }
              .ui.items > .item {
                display: flex;
                align-items: flex-start;
            }
.row.gated-content-list {
  gap: 2rem !important;
}
.ui.items > .item > .content > .description {
    margin-top: 0.6em;
    max-width: auto;
    font-size: 1em;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
          -webkit-box-orient: vertical;
         -webkit-line-clamp: 6;
         display: -webkit-box;
}
.ui.items > .item > .content > .meta {
 margin-left:1rem;

}