.bill-status-card {
  margin: 1rem 0 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.bill-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.bill-session {
  font-size: 0.9em;
  color: #666;
}

.bill-number {
  font-weight: bold;
  font-size: 1.2em;
}

.status-label {
  margin-bottom: 1rem !important;
}

.bill-description {
  font-size: 1.1em;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.sponsors-section,
.current-status {
  margin-bottom: 1.5rem;
}

.sponsors-section h4,
.current-status h4 {
  margin-bottom: 0.5rem;
  color: #333;
}

.status-timeline {
  padding: 1rem 0;
}

.timeline-entry {
  display: grid;
  grid-template-columns: 150px auto 1fr;
  gap: 1rem;
  align-items: start;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
}

.timeline-entry:last-child {
  border-bottom: none;
}

.timeline-date {
  color: #666;
  font-size: 0.9em;
}

.timeline-status .label {
  min-width: 80px;
  text-align: center;
}

.timeline-details {
  color: #333;
  line-height: 1.4;
}

.bill-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
}

.next-action {
  flex: 1;
}

.last-updated {
  text-align: right;
  font-size: 0.9em;
} 