/* Committee Leadership Block - Complete Override Styles */
.committee-leadership-block {
  margin: 2rem 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  max-width: 100% !important;
}

.committee-leadership-heading {
  font-size: 2.5rem !important;
  margin-bottom: 2rem !important;
  color: #333 !important;
  font-weight: 400 !important;
  padding-bottom: 0.5rem !important;
  border-bottom: none !important;
  font-family: inherit !important;
}

.leadership-items {
  display: flex !important;
  flex-direction: column !important;
  gap: 3rem !important;
  margin-top: 0 !important;
}

.leadership-items .item {
  margin-bottom: 2rem !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  display: flex !important;
  align-items: flex-start !important;
}

.leadership-photo {
  width: 150px !important;
  height: 170px !important;
  object-fit: cover !important;
  border: 1px solid #ddd !important;
  margin: 0 !important;
  padding: 0 !important;
}

.leadership-role {
  font-size: 1rem !important;
  color: #666 !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  margin-bottom: 0.3rem !important;
  display: block !important;
}

.leadership-name {
  font-size: 2rem !important;
  margin-top: 0 !important;
  margin-bottom: 0.6rem !important;
  color: #cc3333 !important; 
  font-weight: 400 !important;
  line-height: 1.2 !important;
  font-family: inherit !important;
}

.leadership-title {
  font-size: 1.2rem !important;
  color: #555 !important;
  margin-bottom: 0.25rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
}

.leadership-firm {
  font-size: 1.2rem !important;
  color: #555 !important;
  margin-bottom: 0 !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
}

/* Force Semantic UI to behave correctly */
.ui.items>.item {
  display: flex !important;
  margin-bottom: 2rem !important;
}

.ui.items>.item>.image:not(.ui) {
  width: auto !important;
}

.ui.items>.item>.content {
  padding-left: 1.5rem !important;
  flex: 1 !important;
}

.ui.items>.item>.content>.meta {
  margin: 0 0 0.3rem 0 !important;
}

.ui.items>.item>.content>.header {
  margin: 0 0 0.6rem 0 !important;
}

.ui.items>.item>.content>.description {
  margin: 0 0 0.25rem 0 !important;
  color: #555 !important;
}

/* Fix Semantic UI specificity issues */
.ui.items>.item>.content>.header.leadership-name {
  font-size: 2rem !important;
  color: #cc3333 !important;
}

/* Other states */
.no-leadership-data {
  color: #666 !important;
  font-style: italic !important;
  padding: 1rem !important;
  background-color: #f9f9f9 !important;
  border-radius: 4px !important;
}

.error-state {
  padding: 1rem !important;
  background-color: #fff0f0 !important;
  border-radius: 4px !important;
  border: 1px solid #ffcccc !important;
}

.error-message {
  color: #cc0000 !important;
}

.loading-state {
  padding: 1rem !important;
  background-color: #f0f8ff !important;
  border-radius: 4px !important;
  border: 1px solid #d0e5ff !important;
  color: #0066cc !important;
}

/* Sidebar styles */
.sidebar-image-data {
  background-color: white !important;
  border: 1px solid #eee !important;
  border-radius: 4px !important;
  margin-bottom: 1rem !important;
}

.sidebar-image-data .message {
  margin-bottom: 1rem !important;
}

.sidebar-image-data .accordion {
  margin-top: 1rem !important;
}

.sidebar-image-data .accordion .title {
  background-color: #f8f8f8 !important;
}

.sidebar-note-container {
  background-color: #fffde7 !important;
  padding: 1rem !important;
  border-radius: 4px !important;
  margin-top: 1rem !important;
  border: 1px solid #ffeeba !important;
}

.sidebar-note-container h4 {
  margin-top: 0 !important;
  color: #856404 !important;
  font-weight: bold !important;
}

.sidebar-note-container p {
  color: #666 !important;
  font-size: 0.9rem !important;
} 