.simple-search-wrapper {

  .search-button,
  .anontools {
    display: none;
  }

  input:focus-visible {
    outline-width: 0;
    border-bottom-color: #333;
  }

  input {
    flex: 1;
    border: none;
    border-bottom: 1px solid #CE1E20FF;
  }

  .searchbox {
    display: flex;
    justify-content: space-between;
  }

  button {
    border: none;
    border-bottom: 1px solid #CE1E20FF;
    background: none;
  }

  button svg.icon {
    height: 2rem !important;
    color: #333;
  }

}