a.section {
  color: #fff !important;
  font-size: 1rem;
  font-weight: 600;
}

div.section {
  color: #f3f3f3 !important;
}
#main .ui.breadcrumbs {
  padding: 20px 0;
  background-color: #2e2c2c;
  zmax-width: var(--layout-container-width);
}
.breadcrumbContainer {
  max-width: var(--layout-container-width);
  padding-left:1.5rem;
  padding-right:1.5rem;
}

body #main .breadcrumbs .breadcrumbContainer .ui.breadcrumb > a {
  color: white !important;
  text-decoration: none;
}
.public-ui #main .breadcrumbs .breadcrumb .divider::before {
  color: #000;
  content: '';
  font-size: 7px;
}
#page-contents .ui.breadcrumb .section.active {
  color: #4e7372 !important;
}
